import React from 'react'
import BareLayout from '../components/BareLayout'
import MainContent from '../components/MainContent'
import { graphql } from 'gatsby'

const description = 'Privacy Policy'

export default ({ data, location }) => (
    <BareLayout
        title="Privacy Policy"
        description={description}
        location={location}
        parentClass="content"
    >
        <MainContent parentClass="content">
            <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}></div>
        </MainContent>
    </BareLayout>
)

export const pageQuery = graphql`
    query {
        markdownRemark(
            frontmatter: { privacy: { eq: true } }
        ) {
            html
        }
    }
`
